import React, { Fragment } from "react"
import PostLink from "gatsby-theme-blog-core/src/components/post-link"
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import styles from './post-list.module.scss';

const PostList = ({ posts }) => (
  <Layout customSEO>
    <SEO
        title='Blog'
    />
    <Header/>
    <section className='hero'>
        <div className={ `hero-body ${styles['post-list__hero']}` }>
            <div className='container'>
            <h1 className={ `title has-text-centered ${styles['post-list__hero-title']}` }>
                Blog
            </h1>
            <h2 className={ `subtitle has-text-centered ${styles['post-list__hero-subtitle']}` }>
                Stories from top French tech teams
            </h2>
            </div>
        </div>
    </section>
    <div className={`${styles['post-list__container']}`}>
        <div className={`${styles['post-list__posts']}`}>
            {posts.map((node) => (
                <PostLink key={node.slug} {...node} />
            )).reduce((prev, curr) => 
                [prev, (<hr/>), curr]
            )}
        </div>
    </div>
    <Footer/>
  </Layout>
)

export default PostList