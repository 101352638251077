import React from "react"
import { Link } from "gatsby"
import styles from "./post-link.module.scss";

const PostLink = ({ title, slug, date, excerpt }) => (
  <article className={`${styles['post-link__container']}`}>
    <header className={`${styles['post-link__header']}`}>
        <div className={`${styles['post__details']}`}>
          <Link to='/about/'>Ari Bajo</Link> - <span>{date}</span>
        </div>
        <h2>
          <Link to={slug}>{title || slug}</Link>
        </h2>
    </header>
    <section className={`${styles['post-link__excerpt']}`}>
      <p>{excerpt}</p>
    </section>
    <div className={`${styles['post-link__more']}`}>
      <Link to={slug}>Read more</Link>
    </div>
  </article>
)

export default PostLink